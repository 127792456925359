import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import ClimateSurveyChart from './chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Each spring, beginning last school year, PSI sends out a climate survey to parents, students in grades 3-12, faculty and staff for their opinions in a wide range of areas of the school.  We use these responses to honour the range of perspectives in our community and to identify areas of strength and growth for the school.  We are including here key areas with responses, planned actions and example survey comments regarding the topic.`}</p>
    <ClimateSurveyChart mdxType="ClimateSurveyChart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      